<!-- 角色管理 -->
<template>
    <div class="Roles">
        <div class="" style="margin-left:22px ;margin-top: 39px;">
            <el-button size="small" icon="el-icon-refresh"
                style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                新增
            </el-button>
            
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="100">
            </el-table-column>
            <el-table-column prop="name" label="角色名称" align="center" >
            </el-table-column>
            <el-table-column prop="add_time" label="添加时间" align="center" >
            </el-table-column>
            <el-table-column  label="操作" align="center" width="220" fixed="right">
                <template slot-scope="scope">
                    <el-button class="edit" icon="el-icon-edit" size="mini" 
                    @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" 
                    @click="delt(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination @size-change="handleSizeChange"
             @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tableData:[],
                currentPage: 1,
                currentLimit: 10, //条数
                total: 0,
                bool:true,//判断弹出框
                dialog:false,
                form:{
                    userName:"",//用户名
                    loginName:"",//登录名
                    password:""//密码
                },
                rules:{
                    userName: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                    ],
                    loginName: [
                        { required: true, message: '请输入登录名', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],
                },
                labelPosition: 'right',
            }
        },
        created() {
            this.getList()
        },
        activated() {
          // console.log("我是缓存")  
          this.getList()
        },
        methods:{
            getList(){//获取列表
                this.$request({
                    url:'/api/user/power_list',
                    method: 'POST',
                    data:{
                        page:this.currentPage,
                        limit:this.currentLimit
                    }
                }).then(res=>{
                    console.log(res)
                    this.tableData=res.data.list
                    this.total=res.data.total
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val//条数
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage=val//页数
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            delt(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/user/power_del',
                        method: 'POST',
                        data: {
                            id:row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                        }
                    })
                })
                
            },
            newlyAdded() { //跳转新增功能权限
                this.$router.push({
                    path: "functional"
                })
            }, 
            edit(index,row){ //跳转编辑功能权限
                this.$router.push({
                    path: "editfunctional",
                    query:{
                        id:row.id
                    }
                }) 
            },
            
        }
    }
</script>

<style scoped="scoped">
    .Roles{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 编辑按钮样式 */
    .edit{
       background:#18BC9C ;
       color: #FFFFFF;
    }
    
    /* 功能权限按钮样式 */
    .functional{
        background: url(../../assets/functional.png) 6px 7px no-repeat #F0AD4E;
        color: #fff;
        padding-left: 25px;
    }
    /* 弹出框样式 */
    ::v-deep .el-dialog__title{
        font-weight: bold;
    }
    ::v-deep.el-dialog__footer{
        padding: 0px !important;
    }
    ::v-deep .el-dialog{
        width: 667px !important;
        /* height: 360px !important; */
    }
    ::v-deep .el-dialog__body{
        padding: 10px 20px;
    }
    .el-divider--horizontal{
        margin: 0px;
    }
    .form-1{
       padding: 10px !important;
       width: 500px !important;
       margin: 0px auto !important; 
       margin-top: 5px !important;
    }
     
    .form-1::v-deep .el-input__inner {
        width: 300px;
        height: 37px;
    }
    
    .form-1 ::v-deep .el-form-item__label {
        color: #000000;
    }
    .footer {
        text-align: center;
        margin: 15px 0px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
